<template>
  <div :data-template="content?.['mgnl:template']">
    <PageLayout>
      <ClientOnly>
        <EditorAnnotationElements v-if="isEditMode && !isComponentPreview()" />
      </ClientOnly>

      <Suspense>
        <EditablePage
          v-if="content"
          :key="content?.['@id']"
          :config="config"
          :content="content"
          :template-annotations="templateAnnotations"
        />
        <!-- component with nested async dependencies -->
        <!-- loading state via #fallback slot -->
        <template #fallback>
          <LoadingElement class="container aspect-2/3 lg:aspect-video" />
        </template>
      </Suspense>
    </PageLayout>
  </div>
</template>

<script setup>
import {
  isComponentPreview,
  isEditMode as evaluateEditMode,
  isMagnoliaPreview,
} from "~/utils/content/magnolia";
import { componentMappings } from "~/utils/config/components";
import { DefaultFallbackComponent, EditablePage } from "@magnolia/vue-editor";
import { inject, onMounted, provide, ref } from "vue";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import useMyBritaUser from "~/composables/useMyBritaUser";
import PageLayout from "~/templates/partials/PageLayout";
import EditorAnnotationElements from "~/templates/elements/editor/EditorAnnotationElements.vue";
import LoadingElement from "~/templates/elements/LoadingElement.vue";

const isEditMode = evaluateEditMode();

const config = {
  componentMappings,
  fallbackComponent: DefaultFallbackComponent,
};

const { initMagnoliaContext, loadTemplateAnnotations, contentPath } = useMagnoliaContext();
// await initMagnoliaContext();
// load content
const { content, loadContent } = useMagnoliaContent();

if (import.meta.client) {
  // update mgnl context on client side navigations
  await initMagnoliaContext();
  // load page content
  await loadContent(contentPath.value);
}

// check content restrictions based on content
const { validateContent } = useMyBritaUser();

if (!isEditMode) {
  await validateContent(content);
}

// set page meta / seo information
usePageMeta();

// defaults
provide("fallbackComponent", DefaultFallbackComponent);

const templateAnnotations = ref({});

onMounted(async () => {
  useScrollTracking();

  if (content?.value?.cMasterPage === true) {
    //  early return if content master page
    return;
  }
  if (isEditMode || isMagnoliaPreview()) {
    // call template annotation api from client
    templateAnnotations.value = await loadTemplateAnnotations();
  }
});
</script>

<script>
export default {
  name: "SlugPage",
};
</script>

import useMagnoliaRootNode from "~/composables/useMagnoliaRootNode";
import { sha256 } from "~/utils/helper/hash";

export default async function (title, template) {
  const { currentLanguage } = useMagnoliaLanguage();
  const {
    public: {
      gtmMeta: { websiteType, contentGroup, currentEnvironment },
    },
  } = useRuntimeConfig();
  const { user } = useUser();
  const { isClientNavigation } = useNavigationState();
  const { azureUser } = useAzureUser();
  // need to check for client
  const rootNode = useMagnoliaRootNode();
  const gtm = useGtm(); // auto-imported by the module
  const hashedEmail =
    user?.value?.email || azureUser?.value?.username
      ? await sha256(user?.value?.email || azureUser?.value?.username)
      : "";
  try {
    // // track custom event for each page view
    gtm?.trackEvent({
      event: isClientNavigation.value ? "virtual_page_view" : "page_meta",
      content_group: contentGroup,
      content_group2: template?.toString(),
      login_status: azureUser.value?.homeAccountId ? "logged in" : "not logged in",
      page_country: rootNode?.replace("/", "").toUpperCase(),
      page_environment: currentEnvironment,
      hashed_email: hashedEmail,
      page_language: currentLanguage.value.split("_", 1).toString().toLowerCase(),
      page_location: globalThis.location.href,
      page_title: title,
      traffic_type: "external",
      user_id: azureUser.value?.homeAccountId,
      website_type: websiteType,
      event_source: "source_code",
    });
  } catch (e) {
    console.error("page_meta", e);
  }
}

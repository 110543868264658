<template>
  <div class="container flex flex-col gap-4 font-medium">
    <div v-if="friendlyUrlMissing" class="editmode-warning-box break-all">
      Friendly url missing!
    </div>
    <div v-if="containsMultipleH1" class="editmode-warning-box break-all">
      More than one H1 found !
    </div>
    <div
      v-if="missingLabels.size > 0 || editedLabels.size > 0"
      :class="[missingLabels.size > 0 ? 'editmode-warning-box' : 'editmode-info-box']"
    >
      <AccordionComponent>
        <template #header>
          <div>Common Labels:</div>
        </template>
        <template #content>
          <div class="flex flex-col gap-4 py-4">
            <template v-if="missingLabels.size > 0">
              <div class="text-signal-red">Missing:</div>
              <span
                v-for="label in missingLabels"
                :key="label"
                class="text-link w-auto font-normal text-signal-red"
                @click="onCommonLabelClick(label)"
              >
                {{ label }}
              </span>
            </template>
            <template v-if="editedLabels.size > 0">
              <div>Edited:</div>
              <span
                v-for="label in editedLabels"
                :key="label"
                class="text-link w-auto font-normal"
                @click="onCommonLabelClick(label)"
              >
                {{ label }}
              </span>
            </template>
          </div>
        </template>
      </AccordionComponent>
    </div>
  </div>
</template>

<script setup>
import AccordionComponent from "~/templates/elements/AccordionComponent.vue";
import useCommonLabels from "~/composables/useCommonLabels";
import useMagnoliaContent from "~/composables/useMagnoliaContent";
import "assets/css/editmode/editmode.css";
import { onMounted, ref } from "vue";

const { friendlyUrlMissing } = useMagnoliaContent();
const { missingLabels, editedLabels } = useCommonLabels();
const onCommonLabelClick = (label) => {
  document.getElementById(`common-label-${label}`)?.scrollIntoView();
};

const containsMultipleH1 = ref(false);

onMounted(async () => {
  containsMultipleH1.value = globalThis.document.body.querySelectorAll("h1").length > 1;

  const cssClasses = ["mgnl-edit-mode"];
  if (containsMultipleH1.value) {
    cssClasses.push("contains-multiple-h1");
  }

  useHead({ bodyAttrs: { class: cssClasses.join(" ") } });
});
</script>
